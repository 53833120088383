import { Box } from '@mui/material'
import CheckoutCancelButton from '~/src/components/checkout/CheckoutCancelButton'
import CheckoutConfirmButton from '~/src/components/checkout/CheckoutConfirmButton'

const CheckoutActions = () => {
  return (
    <Box sx={{
      display: 'flex',
      gap: 3,
      pt: 2
    }}>
      <CheckoutConfirmButton />
      <CheckoutCancelButton />
    </Box>
  )
}

export default CheckoutActions
