import { Box } from '@mui/material'
import ShoppingCart from '~/src/components/cart/shopping-cart'

const CheckoutShoppingCart = () => (
  <Box sx={{
    borderRadius: 5,
    mb: 2
  }}>
    <ShoppingCart
      showActions={false}
      showQuantitySelector={false}
      showDeleteButton={false}
      showQuantityAsText
      enableCollapse
    />
  </Box>
)

export default CheckoutShoppingCart
