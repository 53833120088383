import { DateUtils, DeliveryMethodNames } from '@eo-storefronts/eo-core'
import useDeliveryTimeInvalidModal from '~/src/hooks/checkout/useDeliveryTimeInvalidModal'
import { useUpdateCheckoutTime } from '~/src/hooks/checkout/useUpdateCheckoutTime'
import { useConfirmTime } from '~/src/hooks/delivery-methods/useConfirmTime'
import { useAsyncEffect } from '~/src/hooks/useAsyncEffect'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'

const useValidateCheckoutTimeEffect = (): void => {
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const { confirmTimeRequest } = useConfirmTime()
  const { updateCheckoutTime } = useUpdateCheckoutTime()
  const { showModal } = useDeliveryTimeInvalidModal()

  useAsyncEffect(async () => {
    if (
      !checkoutForm.delivery_method.time
      || !checkoutForm.delivery_method.method
      || checkoutForm.delivery_method.method === DeliveryMethodNames.ON_THE_SPOT
    ) {
      return
    }

    const response = await confirmTimeRequest(checkoutForm.delivery_method.time)

    if (!response?.valid && response?.timestamp && checkoutForm.delivery_method.asap) {
      updateCheckoutTime(DateUtils.addMinutes(30, new Date(response.timestamp)))
      return
    }

    if (!response?.valid) {
      showModal()
    }
  }, [ checkoutForm.delivery_method.method ])
}

export default useValidateCheckoutTimeEffect
