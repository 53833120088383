import PaymentMethods from '~/src/components/checkout/payments/PaymentMethods'
import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'

const Payments = () => {
  const { t } = useTranslations()

  return (
    <>
      <Title variant='body1'>{`${t('checkout.payments')} *`}</Title>
      <PaymentMethods />
    </>
  )
}

export default Payments
