import { Box, Collapse } from '@mui/material'
import CheckoutInvoiceAddress from '~/src/components/checkout/CheckoutInvoiceAddress'
import CheckoutInvoiceCompanyDetails from '~/src/components/checkout/CheckoutInvoiceCompanyDetails'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'

const CheckoutInvoiceDetails = () => {
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const muiTheme = useCustomMuiTheme()

  return (
    <Collapse in={checkoutForm.receiveInvoice}>
      <Box
        sx={{
          backgroundColor: 'background.main',
          color: 'background.contrastText',
          borderRadius: 2,
          display: 'grid',
          gap: 2,
          gridTemplateColumns: '30% max-content',
          gridTemplateRows: 'auto',
          gridTemplateAreas: '"company-details invoice-address"',
          p: 2,
          [muiTheme.breakpoints.down('lg')]: {
            gridTemplateColumns: '100%',
            gridTemplateRows: 'repeat(2, auto)',
            gridTemplateAreas: '"company-details" "invoice-address"'
          }
        }}
      >
        <Box sx={{ gridArea: 'company-details' }}>
          <CheckoutInvoiceCompanyDetails />
        </Box>
        <Box sx={{ gridArea: 'invoice-address' }}>
          <CheckoutInvoiceAddress />
        </Box>
      </Box>
    </Collapse>
  )
}

export default CheckoutInvoiceDetails
