import DeliveryMethods from '~/src/components/delivery-methods/DeliveryMethods'
import DeliveryMethodsSummaryBox from '~/src/components/delivery-methods/DeliveryMethodsSummaryBox'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import Title from '~/src/components/mui-wrappers/Title'
import useReLoginAsGuestEffect from '~/src/hooks/checkout/useReLoginAsGuestEffect'
import { useTranslations } from '~/src/hooks/useTranslations'

const DeliveryMethodsSummaryBoxContainer = () => {
  const { t } = useTranslations()
  const { check } = useReLoginAsGuestEffect()

  const _handleOnClose = () => {
    check()
  }

  return (
    <>
      <Title>{t('checkout.delivery_info')}</Title>
      <ResponsiveDialog
        Title={<Title>{t('checkout.delivery_info')}</Title>}
        activator={<DeliveryMethodsSummaryBox />}
        onClose={_handleOnClose}
        maxWidth='sm'
      >
        <DeliveryMethods />
      </ResponsiveDialog>
    </>
  )
}

export default DeliveryMethodsSummaryBoxContainer
