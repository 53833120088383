import { PaymentMethod } from '@eo-storefronts/eo-core'
import { Typography } from '@mui/material'
import usePaymentMethodLabel from '~/src/hooks/checkout/usePaymentMethodLabel'

interface Props {
  method: PaymentMethod,
}

const PaymentMethodLabel = ({ method }: Props) => {
  const { label } = usePaymentMethodLabel(method)

  return (
    <Typography variant="body2">{label}</Typography>
  )
}

export default PaymentMethodLabel
