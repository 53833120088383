import { buildUrl } from '~/src/helpers/router.helper'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useEoValue } from '~/src/hooks/useEoState'
import Params from '~/src/router/enums/params.enum'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { AUTHENTICATION_STATE } from '~/src/stores/authentication'
import useGuestFormFields from '~/src/hooks/forms/guest-form/useGuestFormFields'

const useReLoginAsGuestEffect = () => {
  const { resolve } = useFirmPathResolver()
  const { push } = useRouterPush()
  const authenticationState = useEoValue(AUTHENTICATION_STATE)
  const { isRequired } = useGuestFormFields()

  const check = () => {
    if (authenticationState.loggedInAs !== 'guest') {
      return
    }

    if (
      ((isRequired('phone') && authenticationState.guest?.phone) || !isRequired('phone'))
      && ((isRequired('name') && authenticationState.guest?.firstName && authenticationState.guest?.lastName) || !isRequired('name'))
      && ((isRequired('email') && authenticationState.guest?.email) || !isRequired('email'))
      && ((isRequired('company') && authenticationState.guest?.society) || !isRequired('company'))
    ) {
      return
    }

    push(
      resolve(
        buildUrl(RoutesEnum.EXTRA_INFO_GUEST, new Map<Params, keyof typeof RoutesEnum>([
          [ Params.REDIRECT_TO, 'CHECKOUT' ]
        ]))
      )
    )
  }

  return { check }
}

export default useReLoginAsGuestEffect
