import {
  CreateOrderIngredients,
  CreateOrderPayload,
  CreateOrderProduct,
  CreateOrderProductOptions,
  Customer,
  DeliveryMethodNames,
  Id,
  Modifier,
  ModifierGroup,
  PaymentMethodMethodEnum,
  Product
} from '@eo-storefronts/eo-core'
import { updateBusinessDetails } from '~/src/services/CustomerService'
import { getProductPrice } from '~/src/services/ProductService'
import { AuthenticationState } from '~/src/stores/authentication'
import { CartModifierGroupStateInterface, CartModifierStateInterface } from '~/src/stores/cart'
import { CheckoutFormState } from '~/src/stores/checkout'
import { CartProduct } from '~/src/types/CartProduct'

type OrderProduct =
  Product
  & Omit<CartProduct, 'modifierGroups'>
  & { cartModifierGroups: CartModifierGroupStateInterface[] }

export interface RawOrderInfo {
  firmId: Id | undefined,
  cartProducts: CartProduct[],
  subTotal: number,
  total: number,
  addLoyaltyToOrder: boolean,
}

export default class GenerateOrderPayloadService {
  private readonly _checkoutForm: CheckoutFormState
  private readonly _products: Record<string, Product>
  private readonly _auth: AuthenticationState
  private readonly _customer: Customer | null
  private readonly _modifierGroups: Record<string, ModifierGroup>
  private readonly _modifiers: Record<string, Modifier>

  constructor(
    checkoutForm: CheckoutFormState,
    products: Record<string, Product>,
    modifierGroups: Record<string, ModifierGroup>,
    modifiers: Record<string, Modifier>,
    auth: AuthenticationState,
    customer: Customer | null
  ) {
    this._checkoutForm = checkoutForm
    this._products = products
    this._modifierGroups = modifierGroups
    this._modifiers = modifiers
    this._auth = auth
    this._customer = customer
  }

  public async generate({
    firmId,
    cartProducts,
    subTotal,
    total,
    addLoyaltyToOrder
  }: RawOrderInfo): Promise<CreateOrderPayload> {
    const orderProducts: CreateOrderProduct[] = cartProducts
      .filter((cp: CartProduct) => this._products[cp.id])
      .map((cp: CartProduct) => ({
        ...cp,
        ...this._products[cp.id],
        price: getProductPrice(this._products[cp.id]),
        cartModifierGroups: cp.modifierGroups
      }))
      .map((cp: OrderProduct) => this._flagRemovedIngredients(cp))
      .map((cp: OrderProduct) => this._remapProduct(cp))

    let payload: Partial<CreateOrderPayload> = {
      products: orderProducts,
      add_loyalty_to_order: addLoyaltyToOrder,
      cart_contains_all_products: true,
      asap: this._checkoutForm.delivery_method.asap,
      order_sub_total: subTotal,
      order_total: total
    }

    if (this._checkoutForm.moreInformation) {
      payload = {
        ...payload,
        comment: this._checkoutForm.moreInformation
      }
    }

    if (this._checkoutForm.delivery_method.method) {
      payload = {
        ...payload,
        delivery_method: this._checkoutForm.delivery_method.method
      }
    }

    if (this._checkoutForm.delivery_method.method === DeliveryMethodNames.PICKUP && this._checkoutForm.delivery_method.pickupPoint) {
      payload = {
        ...payload,
        firm_pickup_point_id: this._checkoutForm.delivery_method.pickupPoint.id
      }
    }

    if (this._checkoutForm.delivery_method.method === DeliveryMethodNames.DELIVERY) {
      let delivery_street_nr = this._checkoutForm.delivery_method.address?.house_number

      if (this._checkoutForm.delivery_method.address?.bus) {
        delivery_street_nr += ` ${this._checkoutForm.delivery_method.address.bus}`
      }

      payload = {
        ...payload,
        delivery_street: this._checkoutForm.delivery_method.address?.street,
        delivery_street_nr,
        delivery_zipcode: this._checkoutForm.delivery_method.address?.zip_code,
        delivery_locality: this._checkoutForm.delivery_method.address?.locality,
        delivery_country_id: this._checkoutForm.delivery_method.address?.country_id || this._checkoutForm.delivery_method.address?.country.id
      }
    }

    if (this._checkoutForm.delivery_method.method === DeliveryMethodNames.ON_THE_SPOT) {
      payload = {
        ...payload,
        table_number: this._checkoutForm.delivery_method.table_number ?? undefined,
        custom_table_method_id: this._checkoutForm.delivery_method.table_method ?? undefined
      }
    }

    if (this._checkoutForm.delivery_method.time) {
      payload = {
        ...payload,
        process_timestamp: new Date(this._checkoutForm.delivery_method.time)
      }
    }

    if (this._checkoutForm.delivery_method.timeslotId) {
      payload = {
        ...payload,
        timeslot_id: this._checkoutForm.delivery_method.timeslotId
      }
    }

    if (this._checkoutForm.payment_method?.method === PaymentMethodMethodEnum.CUSTOM) {
      payload = {
        ...payload,
        payment_method_id: 1,
        firm_custom_delivery_pickup_payment_method_id: this._checkoutForm.payment_method?.payment_method_id
      }
    } else if (this._checkoutForm.payment_method?.method === PaymentMethodMethodEnum.PICKUP_POINT_CUSTOM) {
      payload = {
        ...payload,
        payment_method_id: 1,
        pickup_point_custom_pickup_payment_method_id: this._checkoutForm.payment_method?.payment_method_id
      }
    } else {
      payload = {
        ...payload,
        payment_method_id: this._checkoutForm.payment_method?.payment_method_id
      }
    }

    if ((!!this._auth.loggedInAs && this._auth.loggedInAs === 'customer') && this._customer) {
      payload = {
        ...payload,
        user: this._customer
      }
    }

    if (this._auth.guest) {
      payload = {
        ...payload,
        ...this._generateCustomerPayload()
      }
    }

    if (this._checkoutForm.receiveInvoice && this._customer && firmId) {
      await updateBusinessDetails(
        this._customer.id,
        this._customer.uuid || '',
        firmId,
        {
          company: this._customer.company,
          invoice_reference: this._customer.invoice_reference,
          vat_number: this._customer.vat_number
        }
      )

      let address_number = this._checkoutForm.invoiceDetails.address?.house_number

      if (this._checkoutForm.invoiceDetails.address?.bus) {
        address_number += ` ${this._checkoutForm.invoiceDetails.address.bus}`
      }

      payload = {
        ...payload,
        customer_is_professional: true,
        country_id_billing: this._checkoutForm.invoiceDetails.address?.country_id || this._checkoutForm.invoiceDetails.address?.country.id,
        address_billing: `${this._checkoutForm.invoiceDetails.address?.street} ${address_number}`,
        zipcode_billing: this._checkoutForm.invoiceDetails.address?.zip_code,
        locality_billing: this._checkoutForm.invoiceDetails.address?.locality
      }
    }

    if (this._checkoutForm.checkoutOptions.length) {
      payload = {
        ...payload,
        checkout_options: this._checkoutForm.checkoutOptions
      }
    }

    if (this._checkoutForm.coupon?.code) {
      payload = {
        ...payload,
        coupon_code: this._checkoutForm.coupon.code
      }
    }

    return payload as CreateOrderPayload
  }

  private _flagRemovedIngredients(cartProduct: OrderProduct): OrderProduct {
    const cartProductModifierGroups = [ ...cartProduct.cartModifierGroups ]

    // We loop on the default modifier group list
    this._products[cartProduct.id].modifierGroups.forEach((modifierGroupId: string) => {
      const modifierGroup = this._modifierGroups[modifierGroupId]

      // Is the modifier group present in the cart product?
      let cartProductModifierGroupIndex = cartProductModifierGroups.findIndex((cpmg: CartModifierGroupStateInterface) => cpmg.id === modifierGroup.id)

      // No? We add it with NO modifiers. And we set the index.
      if (cartProductModifierGroupIndex === -1) {
        cartProductModifierGroups.push({
          ...modifierGroup,
          modifiers: []
        })
        cartProductModifierGroupIndex = cartProductModifierGroups.length - 1
      }

      // Then, we loop on the modifiers
      modifierGroup.modifiers.forEach((modifierId: string) => {
        const modifier = this._modifiers[modifierId]

        // We do not have an ingredient, we skip the rest
        if (modifier.type !== 'ingredient') {
          return
        }

        // Is the modifier assigned in the cart product?
        const modifiers = [ ...cartProductModifierGroups[cartProductModifierGroupIndex].modifiers ]
        const currentModifierIndex = modifiers.findIndex((i: CartModifierStateInterface) => i.id === modifier.id)

        // it's present and flagged in remove so change quantity
        if (currentModifierIndex !== -1 && modifiers[currentModifierIndex].action === 'rem') {
          modifiers.splice(
            currentModifierIndex,
            1,
            {
              ...modifiers[currentModifierIndex],
              quantity: 1
            }
          )
        }

        cartProductModifierGroups[cartProductModifierGroupIndex] = {
          ...cartProductModifierGroups[cartProductModifierGroupIndex],
          modifiers
        }
      })
    })

    return {
      ...cartProduct,
      cartModifierGroups: cartProductModifierGroups.filter((modifierGroups: CartModifierGroupStateInterface) => modifierGroups.modifiers.length > 0)
    }
  }

  private _remapProduct(orderProduct: OrderProduct): CreateOrderProduct {
    const ingredients: Array<CreateOrderIngredients> = []
    const product_options: Array<CreateOrderProductOptions> = []

    orderProduct.cartModifierGroups.forEach((cartModifierGroup: CartModifierGroupStateInterface) => {
      if (!this._modifierGroups[cartModifierGroup.id] || !this._modifierGroups[cartModifierGroup.id].modifiers.length) {
        return
      }

      cartModifierGroup.modifiers.forEach((modifier: CartModifierStateInterface) => {
        if (!this._modifiers[modifier.id]) {
          return
        }

        if (this._modifiers[modifier.id].type === 'ingredient') {
          ingredients.push({
            id: Number(this._modifiers[modifier.id].id.split('-').pop()),
            type: this._modifiers[modifier.id].ingredientType,
            price: this._modifiers[modifier.id].price,
            quantity: modifier.quantity,
            action: (modifier as any).action === 'pre' ? 'add' : (modifier as any).action,
            selected: this._modifiers[modifier.id].isDefault || false
          })
        } else {
          product_options.push({
            id: Number(this._modifiers[modifier.id].id.split('-').pop()),
            group_id: Number(cartModifierGroup.id.split('-')[1]),
            price: this._modifiers[modifier.id].price
          })
        }
      })
    })

    return {
      id: orderProduct.id,
      price: orderProduct.is_loyalty || orderProduct.is_coupon ? 0 : orderProduct.price,
      parent_cat_id: orderProduct.categoryId,
      cat_id: orderProduct.categoryId || '',
      quantity: orderProduct.quantity,
      unit_id: orderProduct.unit?.id || '',
      weight: orderProduct.weight,
      comment: orderProduct.comment,
      is_loyalty: orderProduct.is_loyalty || false,
      is_coupon: orderProduct.is_coupon || false,
      ingredients,
      product_options
    }
  }

  /**
   * This method will generate the customer payload taking in case of the keys are undefined or empty strings
   * set to undefined when the keys = an empty string
   * @returns {Pick<CreateOrderPayload, 'customer_company' | 'customer_email' | 'customer_phone' | 'customer_name'>}
   */
  private _generateCustomerPayload = (): Pick<CreateOrderPayload, 'customer_company' | 'customer_email' | 'customer_phone' | 'customer_name'> => {
    return {
      customer_company: this._auth.guest?.society || undefined,
      customer_email: this._auth.guest?.email || undefined,
      customer_phone: this._auth.guest?.phone || undefined,
      customer_name: this._formatCustomerName() || undefined
    }
  }

  /**
   * This method will format the customer name depending on which properties are set
   * @returns {string}
   */
  private _formatCustomerName = (): string => {
    return [
      this._auth.guest?.firstName,
      this._auth.guest?.lastName
    ].join(' ').trim()
  }
}
