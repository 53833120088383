import useSetFirstAvailableTime from '~/src/hooks/delivery-methods/useSetFirstAvailableTime'
import { useConfirmDialog } from '~/src/hooks/layout/useConfirmDialog'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useSetEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import routes from '~/src/router/enums/routes.enum'
import { INTERVALS_STATE, TIMESLOTS_STATE } from '~/src/stores/timeslots'

interface ReturnsType {
  showModal(): void,
}

const useDeliveryTimeInvalidModal = (): ReturnsType => {
  const { t } = useTranslations()
  const { triggerConfirmDialog } = useConfirmDialog()
  const { resolve } = useFirmPathResolver()
  const { push } = useRouterPush()
  const setIntervals = useSetEoState(INTERVALS_STATE)
  const setTimeslots = useSetEoState(TIMESLOTS_STATE)
  const { setFirstAvailableTime } = useSetFirstAvailableTime()

  const handleOnConfirm = (): void => {
    setIntervals({})
    setTimeslots({})
    void setFirstAvailableTime()
    push(resolve(routes.FIRM_HOME))
  }

  const showModal = (): void => {
    triggerConfirmDialog({
      message: t('checkout.errors.delivery_time_not_valid_anymore'),
      onConfirm: handleOnConfirm
    })
  }

  return {
    showModal
  }
}

export default useDeliveryTimeInvalidModal
