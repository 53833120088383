import CanOnlyOrderOnOpeningHour from '~/src/components/error/CanOnlyOrderOnOpeningHour'
import FirmClosed from '~/src/components/firms/FirmClosed'

const NoOrdersAllowed = () => {
  return (
    <>
      <FirmClosed sx={{ mb: 2 }} />
      <CanOnlyOrderOnOpeningHour />
    </>
  )
}

export default NoOrdersAllowed
