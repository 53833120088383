import { Id } from '@eo-storefronts/eo-core'
import useLogAnalyticEcommerceEvent from '~/src/hooks/analytics/useLogAnalyticEcommerceEvent'
import { useEoValue } from '~/src/hooks/useEoState'
import { CART_OF_FIRM_SELECTOR, CART_TOTAL_ITEMS_SELECTOR, CART_TOTAL_SELECTOR } from '~/src/stores/cart'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { CartProduct } from '~/src/types/CartProduct'

interface ReturnsType {
  log(orderId: Id): void,
}

const useLogPurchase = (): ReturnsType => {
  const {
    logGoogleEvent: logGoogleAnalyticEvent,
    logFacebookEvent: logFacebookAnalyticEvent
  } = useLogAnalyticEcommerceEvent()
  const firm = useEoValue(FIRM_SELECTOR)
  const cart = useEoValue(CART_OF_FIRM_SELECTOR)
  const checkoutForm = useEoValue<CheckoutFormState>(CHECKOUT_FORM_STATE)
  const amountOfItemsInCart = useEoValue<number>(CART_TOTAL_ITEMS_SELECTOR)
  const cartTotal = useEoValue<number>(CART_TOTAL_SELECTOR({}))

  const log = (orderId: Id): void => {
    const currency: string | undefined = firm?.settings.currency.code
    const value = Number(cartTotal.toFixed(2))

    logGoogleAnalyticEvent('purchase', {
      transaction_id: orderId.toString(),
      coupon: checkoutForm.coupon?.code,
      items: cart,
      currency,
      value
    })
    logFacebookAnalyticEvent('Purchase', {
      content_ids: cart.map((cartProduct: CartProduct) => cartProduct.id.toString()),
      contents: [ cart ],
      content_type: 'product_group',
      num_items: amountOfItemsInCart,
      currency,
      value
    })
  }

  return { log }
}

export default useLogPurchase
