import useBackButton from '~/src/hooks/layout/useBackButton'
import { useLayout } from '~/src/hooks/layout/useLayout'
import routes from '~/src/router/enums/routes.enum'

const useGetCheckoutTemplate = (): void => {
  useLayout('checkout')
  useBackButton({ label: 'footer.cancel_order', href: routes.CART, resolve: true })
}

export default useGetCheckoutTemplate
