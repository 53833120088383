import { useSnackbar } from 'notistack'
import { useState } from 'react'
import useDeliveryTimeInvalidModal from '~/src/hooks/checkout/useDeliveryTimeInvalidModal'
import { useTranslations } from '~/src/hooks/useTranslations'
import usePlaceOrder from '~/src/hooks/checkout/usePlaceOrder'

interface ReturnsType {
  postOrder(): Promise<void>,
  loading: boolean,
}

const useCreateOrder = (): ReturnsType => {
  const { t } = useTranslations()
  const [ loading, setLoading ] = useState(false)
  const { placeOrder } = usePlaceOrder()
  const { enqueueSnackbar } = useSnackbar()
  const { showModal } = useDeliveryTimeInvalidModal()

  const postOrder = async () => {
    setLoading(true)

    try {
      await placeOrder()
    } catch (e: any) {
      if (e.body?.errors) {
        for (const error in e.body.errors) {
          if (!e.body.errors.hasOwnProperty(error)) {
            continue
          }

          let message = e.body.errors[error]

          if (message instanceof Array) {
            message = e.body.errors[error]?.join(' ')
          }

          if (typeof message === 'object') {
            if (message.error === 'INGREDIENT_IS_SNOOZED' && message.ingredient_name) {
              message = t('checkout.errors.snoozed_item', {
                item: message.ingredient_name
              })
            }

            if (message.error === 'OPTION_IS_SNOOZED' && message.option_name) {
              message = t('checkout.errors.snoozed_item', {
                item: message.option_name
              })
            }
          }

          enqueueSnackbar({
            message: typeof message === 'string' ? message : JSON.stringify(message),
            variant: 'error'
          })
        }
      } else {
        enqueueSnackbar({
          message: e.message,
          variant: 'error'
        })
      }

      if (e.body?.code === 'INVALID_TIMESTAMP') {
        showModal()
      }
    }

    setLoading(false)
  }

  return {
    postOrder,
    loading
  }
}

export default useCreateOrder
