import { BasicResponse, CreateOrderPayload, PaymentMethodMethodEnum, UuidService } from '@eo-storefronts/eo-core'
import usePaymentMethodPay from '~/src/hooks/checkout/usePaymentMethodPay'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import GenerateOrderPayloadService from '~/src/services/GenerateOrderPayloadService'
import { createOrder } from '~/src/services/OrderService'
import { AUTHENTICATION_STATE } from '~/src/stores/authentication'
import { CART_OF_FIRM_SELECTOR, CART_SUB_TOTAL_SELECTOR, CART_TOTAL_SELECTOR } from '~/src/stores/cart'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { CUSTOMER_STATE, GET_CUSTOMER_ID_SELECTOR } from '~/src/stores/customer'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { ADD_LOYALTY_TO_ORDER_SELECTOR } from '~/src/stores/loyalty'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'
import { MODIFIERS_STATE } from '~/src/stores/modifiers'
import { PRODUCTS_STATE } from '~/src/stores/product'
import { BRAND_STATE } from '~/src/stores/brand'
import { useRef } from 'react'
import useRedirectAfterCheckout from '~/src/hooks/checkout/useRedirectAfterCheckout'
import { LAST_GUEST_ORDER_ID_STATE } from '~/src/stores/orders'
import deepEqual from '~/src/helpers/deepEqual'

interface ReturnsType {
  placeOrder(): Promise<void>,
}

const usePlaceOrder = (): ReturnsType => {
  const { pay } = usePaymentMethodPay()
  const firm = useEoValue(FIRM_SELECTOR)
  const brand = useEoValue(BRAND_STATE)
  const customer = useEoValue(CUSTOMER_STATE)
  const customerId = useEoValue(GET_CUSTOMER_ID_SELECTOR)
  const auth = useEoValue(AUTHENTICATION_STATE)
  const cartProducts = useEoValue(CART_OF_FIRM_SELECTOR)
  const products = useEoValue(PRODUCTS_STATE)
  const modifierGroups = useEoValue(MODIFIER_GROUPS_STATE)
  const modifiers = useEoValue(MODIFIERS_STATE)
  const subTotal = useEoValue(CART_SUB_TOTAL_SELECTOR)
  const total = useEoValue(CART_TOTAL_SELECTOR({}))
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const addLoyaltyToOrder = useEoValue(ADD_LOYALTY_TO_ORDER_SELECTOR)
  const setLastGuestOrderId = useSetEoState(LAST_GUEST_ORDER_ID_STATE)
  const orderRef = useRef<CreateOrderPayload | null>(null)
  const { redirect } = useRedirectAfterCheckout()
  const generateOrderPayloadService = new GenerateOrderPayloadService(
    checkoutForm,
    products,
    modifierGroups,
    modifiers,
    auth,
    customer
  )

  const _generatePayload = async (): Promise<CreateOrderPayload> => {
    return generateOrderPayloadService.generate({
      firmId: firm?.id,
      cartProducts,
      subTotal,
      total,
      addLoyaltyToOrder
    })
  }

  const placeOrder = async () => {
    if (!firm) {
      return
    }

    const orderPayload = await _generatePayload()

    if (
      (
        Number(checkoutForm.payment_method?.payment_method_id) === 1
        || checkoutForm.payment_method?.method === PaymentMethodMethodEnum.CUSTOM
      )
      && deepEqual(orderRef.current, orderPayload)
    ) {
      return redirect()
    }

    const response: BasicResponse = await createOrder(
      orderPayload,
      firm.id,
      brand?.uuid || '',
      customerId,
      UuidService.generate()
    )

    orderRef.current = orderPayload
    setLastGuestOrderId(response.id)

    /**
     * Redirect the user to the UPCOMING_ORDER page
     */
    redirect()
    pay(response.id)
  }

  return { placeOrder }
}

export default usePlaceOrder
