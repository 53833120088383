import {
  ApiConfiguration,
  Firm,
  GetPaymentStatusResponse,
  Id,
  PaymentService,
  PaymentType,
  RedirectUrls
} from '@eo-storefronts/eo-core'
import RoutesEnum from '~/src/router/enums/routes.enum'
import EnvUtils from '~/src/utils/EnvUtils'

export const defaultPaymentRedirectUrls = (isLoggedIn: boolean): RedirectUrls => ({
  acceptUrl: `${RoutesEnum.ORDERS}/${RoutesEnum.UPCOMING}?thank-you`,
  declineUrl: isLoggedIn ? RoutesEnum.ORDERS : RoutesEnum.MENU,
  exceptionUrl: isLoggedIn ? RoutesEnum.ORDERS : RoutesEnum.MENU,
  cancelUrl: `${RoutesEnum.CHECKOUT}?cancelled`
})

export const payWith = async (brand: Firm, paymentType: PaymentType, orderId: Id, firmId: Id, storeId: Id, customerId: Id, uuid: string, redirectUrls: RedirectUrls): Promise<unknown> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())
  ApiConfiguration.addOrReplaceHeader('storeId', storeId.toString())
  ApiConfiguration.addOrReplaceHeader('customerId', customerId.toString())
  ApiConfiguration.addOrReplaceHeader('uuid', uuid.toString())

  const redirectUrlsGenerated: RedirectUrls = generateRedirectUrl(redirectUrls, brand)

  return PaymentService.pay(paymentType, orderId, redirectUrlsGenerated)
}

export const getPaymentStatus = async (firmId: Id, orderId: Id, customerId: Id): Promise<GetPaymentStatusResponse> => {
  clearHeaders()
  ApiConfiguration.addOrReplaceHeader('firmId', firmId.toString())
  ApiConfiguration.addOrReplaceHeader('customerId', customerId.toString())

  return PaymentService.getPaymentStatus(orderId)
}

const generateReturnUrl = (path: string, brand: Firm): string => {
  const { host, pathname } = window.location
  let protocol: string = window.location.protocol

  if (EnvUtils.isDeviceApp) {
    protocol = brand.settings.app_url.replace('//', '')
  }

  let baseUrl = `${protocol}//${host}/${pathname.split('/')[1]}/${pathname.split('/')[2]}`

  if (!brand.is_multifirm) {
    baseUrl = `${protocol}//${host}/${pathname.split('/')[1]}`
  }

  return `${baseUrl}${path}`
}

const generateRedirectUrl = (redirectUrls: RedirectUrls, brand: Firm): RedirectUrls => {
  return {
    acceptUrl: generateReturnUrl(redirectUrls.acceptUrl, brand),
    declineUrl: generateReturnUrl(redirectUrls.declineUrl, brand),
    exceptionUrl: generateReturnUrl(redirectUrls.exceptionUrl, brand),
    cancelUrl: generateReturnUrl(redirectUrls.cancelUrl, brand)
  }
}

const clearHeaders = (): void => {
  [ 'firmId', 'customerId', 'uuid', 'storeId' ].forEach((key: string) => {
    ApiConfiguration.deleteHeader(key)
  })
}
