import { PaymentMethod as PaymentMethodType } from '@eo-storefronts/eo-core'
import { FormControlLabel, Radio } from '@mui/material'
import PaymentMethodLabel from '~/src/components/checkout/payments/PaymentMethodLabel'
import Base64 from '~/src/utils/Base64Utils'

interface Props {
  selected: boolean,
  payment_method: PaymentMethodType,
}

const PaymentMethod = ({ selected, payment_method }: Props) => (
  <FormControlLabel
    value={Base64.encode(payment_method)}
    sx={{
      backgroundColor: !selected ? 'background.main' : undefined,
      color: !selected ? 'background.contrastText' : undefined,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: !selected ? 'background.main' : 'primary.main',
      borderRadius: 'var(--btn-border-radius)',
      pr: 2
    }}
    control={<Radio />}
    label={<PaymentMethodLabel method={payment_method} />}
  />
)

export default PaymentMethod
