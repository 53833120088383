import {
  CustomPaymentMethod,
  DeliveryMethodNames,
  PaymentMethod as PaymentMethodType,
  PaymentMethod,
  PaymentMethodMethodEnum,
  PickupPoint
} from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  paymentMethods: PaymentMethod[],
}

const usePaymentMethods = (): ReturnsType => {
  const [ paymentMethods, setPaymentMethods ] = useState<PaymentMethod[]>([])
  const firm = useEoValue(FIRM_SELECTOR)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)

  useEffect(() => {
    if (!firm || !firm.settings.payment_methods.length) {
      return
    }

    let paymentMethods: PaymentMethodType[] = firm.settings.payment_methods
      .filter(
        (method: PaymentMethodType) => method.method as string === checkoutForm.delivery_method.method
      )

    if (
      checkoutForm.delivery_method.method === DeliveryMethodNames.PICKUP
      && checkoutForm.delivery_method.pickupPoint
      && !checkoutForm.delivery_method.pickupPoint.main_branch
    ) {
      const pickupPointDetails: PickupPoint | undefined = (firm.settings?.delivery_methods[DeliveryMethodNames.PICKUP]?.pickup_points || [])
        .find((pickupPoint: PickupPoint) => pickupPoint.id === checkoutForm.delivery_method.pickupPoint?.id)

      if (pickupPointDetails) {
        paymentMethods = [
          ...pickupPointDetails.payment_methods
        ]

        if (pickupPointDetails.custom_payment_methods?.length) {
          paymentMethods = [
            ...paymentMethods,
            ...pickupPointDetails.custom_payment_methods.map((customPaymentMethod: CustomPaymentMethod) => ({
              payment_method_id: customPaymentMethod.id,
              payment_method: customPaymentMethod.name,
              method: PaymentMethodMethodEnum.PICKUP_POINT_CUSTOM
            }))
          ]
        }
      }

    }

    if (checkoutForm.delivery_method.method === DeliveryMethodNames.DELIVERY && firm.settings.custom_delivery_payment_methods) {
      paymentMethods = [
        ...paymentMethods,
        ...firm.settings.custom_delivery_payment_methods.map((customPaymentMethod: CustomPaymentMethod) => ({
          payment_method_id: customPaymentMethod.id,
          payment_method: customPaymentMethod.name,
          method: PaymentMethodMethodEnum.CUSTOM
        }))
      ]
    }

    if (
      checkoutForm.delivery_method.method === DeliveryMethodNames.PICKUP
      && firm.settings.custom_pickup_payment_methods
      && (!checkoutForm.delivery_method.pickupPoint || checkoutForm.delivery_method.pickupPoint?.main_branch)
    ) {
      paymentMethods = [
        ...paymentMethods,
        ...firm.settings.custom_pickup_payment_methods.map((customPaymentMethod: CustomPaymentMethod) => ({
          payment_method_id: customPaymentMethod.id,
          payment_method: customPaymentMethod.name,
          method: PaymentMethodMethodEnum.CUSTOM
        }))
      ]
    }

    setPaymentMethods(paymentMethods)
  }, [ firm, checkoutForm.delivery_method.pickupPoint, checkoutForm.delivery_method ])

  return { paymentMethods }
}

export default usePaymentMethods
