import { DeliveryMethodNames, PaymentMethod, PaymentMethodMethodEnum } from '@eo-storefronts/eo-core'
import { useCallback } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  label: string,
}

const usePaymentMethodLabel = (method: PaymentMethod): ReturnsType => {
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const firm = useEoValue(FIRM_SELECTOR)
  const { t } = useTranslations()

  const _getLabel = useCallback((): string => {
    if (!firm) {
      return ''
    }

    if (
      method.method === PaymentMethodMethodEnum.CUSTOM
      || method.method === PaymentMethodMethodEnum.PICKUP_POINT_CUSTOM
    ) {
      return method.payment_method
    }

    if (
      method.payment_method_id === 1
      && checkoutForm.delivery_method.method === DeliveryMethodNames.DELIVERY
    ) {
      return firm.labels?.cash_delivery || ''
    }

    if (
      method.payment_method_id === 1
      && checkoutForm.delivery_method.method === DeliveryMethodNames.PICKUP
    ) {
      return firm.labels?.cash_pickup || ''
    }

    if (
      method.payment_method_id === 1
      && checkoutForm.delivery_method.method === DeliveryMethodNames.ON_THE_SPOT
    ) {
      return firm.labels?.cash_table || ''
    }

    if (method.payment_method_id === 2) {
      return 'Bancontact'
    }

    if (method.payment_method_id === 3) {
      return 'iDeal'
    }

    return t('checkout.online_payment')
  }, [ firm, checkoutForm ])

  return { label: _getLabel() }
}

export default usePaymentMethodLabel
