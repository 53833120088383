import { Firm } from '@eo-storefronts/eo-core'
import { useEffect } from 'react'
import useLogAnalyticEcommerceEvent from '~/src/hooks/analytics/useLogAnalyticEcommerceEvent'
import { useEoValue } from '~/src/hooks/useEoState'
import { CART_OF_FIRM_SELECTOR, CART_TOTAL_ITEMS_SELECTOR, CART_TOTAL_SELECTOR } from '~/src/stores/cart'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { CartProduct } from '~/src/types/CartProduct'

const useLogBeginCheckout = (): void => {
  const {
    logGoogleEvent: logGoogleAnalyticEvent,
    logFacebookEvent: logFacebookAnalyticEvent
  } = useLogAnalyticEcommerceEvent()
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const cart = useEoValue<CartProduct[]>(CART_OF_FIRM_SELECTOR)
  const cartTotal = useEoValue<number>(CART_TOTAL_SELECTOR({}))
  const amountOfItemsInCart = useEoValue<number>(CART_TOTAL_ITEMS_SELECTOR)
  const { coupon } = useEoValue<CheckoutFormState>(CHECKOUT_FORM_STATE)

  useEffect(() => {
    const currency: string | undefined = firm?.settings.currency.code
    const value = Number(cartTotal.toFixed(2))
    logGoogleAnalyticEvent('begin_checkout', {
      currency,
      value,
      coupon,
      items: cart
    })
    logFacebookAnalyticEvent('InitiateCheckout', {
      content_ids: cart.map((cartProduct: CartProduct) => cartProduct.id.toString()),
      contents: [ cart ],
      content_type: 'product_group',
      num_items: amountOfItemsInCart,
      currency,
      value
    })
  }, [])
}

export default useLogBeginCheckout
