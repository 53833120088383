import { Checkbox, FormControlLabel } from '@mui/material'
import { useEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'

const CheckoutReceiveInvoiceCheckbox = () => {
  const { t } = useTranslations()
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)

  const handleOnChange = () => {
    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      receiveInvoice: !state.receiveInvoice
    }))
  }

  return (
    <FormControlLabel
      control={(
        <Checkbox
          checked={checkoutForm.receiveInvoice}
          onChange={handleOnChange}
        />
      )}
      label={t('checkout.receive_an_invoice')}
    />
  )
}

export default CheckoutReceiveInvoiceCheckbox
