import { DeliveryMethodNames } from '@eo-storefronts/eo-core'
import DeliveryDining from '@mui/icons-material/DeliveryDining'
import TableRestaurant from '@mui/icons-material/TableRestaurant'
import LocalMall from '@mui/icons-material/LocalMall'

interface Props {
  method: DeliveryMethodNames,
}

const DeliveryMethodsSummaryBoxDeliveryIcon = ({ method }: Props) => {
  switch (method) {
    case DeliveryMethodNames.DELIVERY:
      return <DeliveryDining />
    case DeliveryMethodNames.PICKUP:
      return <LocalMall />
    case DeliveryMethodNames.ON_THE_SPOT:
      return <TableRestaurant />
    default:
      return null
  }
}

export default DeliveryMethodsSummaryBoxDeliveryIcon
