import Button from '~/src/components/mui-wrappers/buttons/Button'
import useCreateOrder from '~/src/hooks/checkout/useCreateOrder'
import useValidateCheckout from '~/src/hooks/checkout/useValidateCheckout'
import useValidateOrder, { OrderErrorType } from '~/src/hooks/orders/useValidateOrder'
import { useTranslations } from '~/src/hooks/useTranslations'

const CheckoutConfirmButton = () => {
  const { t } = useTranslations()
  const { postOrder, loading } = useCreateOrder()
  const { errors: checkoutErrors } = useValidateCheckout()
  const { errors: orderErrors } = useValidateOrder()

  const disabled =
    !!checkoutErrors.length
    || !!orderErrors.filter((error: OrderErrorType) => error.variant === 'error').length

  return (
    <Button
      variant='contained'
      preventMultipleClick
      loading={loading}
      disabled={disabled}
      onClick={postOrder}
    >
      {t('footer.confirm_order')}
    </Button>
  )
}

export default CheckoutConfirmButton
