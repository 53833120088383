import { DeliveryMethodNames } from '@eo-storefronts/eo-core'
import { Box, Typography } from '@mui/material'
import { useContext } from 'react'
import DeliveryMethodsTimeSummary from '~/src/components/delivery-methods/DeliveryMethodsTimeSummary'
import EatInSummary from '~/src/components/delivery-methods/EatInSummary'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import DeliveryMethodsSummaryBoxDeliveryIcon
  from '~/src/components/delivery-methods/DeliveryMethodsSummaryBoxDeliveryIcon'

const DeliveryMethodsSummaryBox = () => {
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const { t } = useTranslations()
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  const handleOnClick = () => {
    responsiveDialogContext?.toggle && responsiveDialogContext.toggle()
  }

  if (!checkoutForm.delivery_method.method) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'inline-block',
        px: 2,
        py: 1,
        my: 1,
        borderRadius: 2,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'primary.main',
        color: 'primary.main',
        cursor: 'pointer'
      }}
      onClick={handleOnClick}
    >
      <Typography sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1
      }}>
        <DeliveryMethodsSummaryBoxDeliveryIcon method={checkoutForm.delivery_method.method} />
        {t(`delivery_methods.${checkoutForm.delivery_method.method}`)}
      </Typography>
      {checkoutForm.delivery_method.method !== DeliveryMethodNames.ON_THE_SPOT && <DeliveryMethodsTimeSummary />}
      {checkoutForm.delivery_method.method === DeliveryMethodNames.ON_THE_SPOT && <EatInSummary />}
    </Box>
  )
}

export default DeliveryMethodsSummaryBox
