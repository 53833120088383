export default class Base64 {
  public static encode<T extends {}>(data: T | null): null | string {
    if (!data) {
      return null
    }

    return btoa(JSON.stringify(data))
  }

  public static decode<T extends {}>(data: string): T | null {
    try {
      return JSON.parse(atob(data)) as T
    } catch {
      return null
    }
  }
}

