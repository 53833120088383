import { Customer } from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { CUSTOMER_STATE } from '~/src/stores/customer'

interface ReturnsType {
  errors: string[],
}

const useValidateCheckout = (): ReturnsType => {
  const checkoutForm = useEoValue<CheckoutFormState>(CHECKOUT_FORM_STATE)
  const customer = useEoValue<Customer | null>(CUSTOMER_STATE)
  const [ errors, setErrors ] = useState<string[]>([])
  const { t } = useTranslations()

  useEffect(() => {
    const checkoutErrors: string[] = []

    if (checkoutForm.payment_method === null) {
      checkoutErrors.push(t('checkout.errors.select_a_payment_method'))
    }

    if (checkoutForm.receiveInvoice && !customer?.company) {
      checkoutErrors.push(t('checkout.errors.invoice_company_name_required'))
    }

    if (checkoutForm.receiveInvoice && !customer?.vat_number) {
      checkoutErrors.push(t('checkout.errors.invoice_vat_number_required'))
    }

    if (checkoutForm.receiveInvoice && !checkoutForm.invoiceDetails.address) {
      checkoutErrors.push(t('checkout.errors.invoice_address_required'))
    }

    setErrors(checkoutErrors)
  },[ checkoutForm ])

  return { errors }
}

export default useValidateCheckout
