import { Browser } from '@capacitor/browser'
import { useEffect } from 'react'
import EnvUtils from '~/src/utils/EnvUtils'

interface ReturnsType {
  open(url: string, handleBrowserPageLoaded?: () => void, handleBrowserFinished?: () => void, target?: string): void,
}

const useOpenExternalUrl = (): ReturnsType => {
  const open = (url: string, handleBrowserPageLoaded?: () => void, handleBrowserFinished?: () => void, target = '_self') => {
    if (EnvUtils.isDeviceWeb) {
      window.open(url, target)
    } else {
      Browser.open({ url, presentationStyle: 'popover' })
        .then(() => {
          handleBrowserPageLoaded && Browser.addListener('browserPageLoaded', handleBrowserPageLoaded)
          handleBrowserFinished && Browser.addListener('browserFinished', handleBrowserFinished)
        })
    }
  }

  useEffect(() => {
    return () => {
      void Browser.removeAllListeners()
    }
  }, [])

  return { open }
}

export default useOpenExternalUrl
