import { Theme as MuiTheme, Typography, useMediaQuery } from '@mui/material'
import { useEffect } from 'react'
import CheckoutActions from '~/src/components/checkout/CheckoutActions'
import CheckoutDeliveryAddress from '~/src/components/checkout/CheckoutDeliveryAddress'
import CheckoutInvoicing from '~/src/components/checkout/CheckoutInvoicing'
import CheckoutMessage from '~/src/components/checkout/CheckoutMessage'
import CheckoutShoppingCart from '~/src/components/checkout/CheckoutShoppingCart'
import Payments from '~/src/components/checkout/payments/Payments'
import DeliveryMethodsSummaryBoxContainer from '~/src/components/delivery-methods/DeliveryMethodsSummaryBoxContainer'
import NoOrdersAllowed from '~/src/components/error/NoOrdersAllowed'
import Card from '~/src/components/mui-wrappers/Card'
import Title from '~/src/components/mui-wrappers/Title'
import GoBackToTheShopButton from '~/src/components/navigation/GoBackToTheShopButton'
import useValidateCheckoutTimeEffect from '~/src/hooks/checkout/useValidateCheckoutTimeEffect'
import useLoyaltyProductsModal from '~/src/hooks/customer/useLoyaltyProductsModal'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { useSetEoState } from '~/src/hooks/useEoState'
import { useRouterQuery } from '~/src/hooks/router/useRouterQuery'

const CheckoutContainer = () => {
  const { t } = useTranslations()
  const { params } = useRouterQuery()
  const isSmallDevice = useMediaQuery((theme: MuiTheme) => theme.breakpoints.down('md'))
  const { checkLoyaltyProductIsSelected } = useLoyaltyProductsModal()
  const setCheckoutForm = useSetEoState(CHECKOUT_FORM_STATE)

  useValidateCheckoutTimeEffect()

  useEffect(() => {
    checkLoyaltyProductIsSelected()

    if (localStorage.getItem('checkoutForm') && params['cancelled']) {
      setCheckoutForm(JSON.parse(localStorage.getItem('checkoutForm') as string))
      localStorage.removeItem('checkoutForm')
    }
  }, [ params ])

  return (
    <>
      <NoOrdersAllowed />
      <GoBackToTheShopButton />
      <Card>
        <Title
          variant='h5'
          sx={{ pb: 2 }}
        >
          {t('checkout.title')}
        </Title>
        {isSmallDevice && <CheckoutShoppingCart />}
        <DeliveryMethodsSummaryBoxContainer />
        <CheckoutDeliveryAddress />
        <CheckoutInvoicing />
        <Payments />
        <CheckoutMessage />
        <Typography variant='body1'>
          {`* ${t('global.required')}`}
        </Typography>
        <CheckoutActions />
      </Card>
    </>
  )
}

export default CheckoutContainer
