import { Alert } from '@mui/material'
import { useFirmAcceptsOrder } from '~/src/hooks/orders/useFirmAcceptsOrder'
import { useTranslations } from '~/src/hooks/useTranslations'

const CanOnlyOrderOnOpeningHour = () => {
  const { t } = useTranslations()
  const { checkOrdersOnlyDuringOpeningHours } = useFirmAcceptsOrder()

  if (checkOrdersOnlyDuringOpeningHours()) {
    return null
  }

  return (
    <Alert
      sx={{
        alignItems: 'center'
      }}
      severity='error'
    >
      {t('checkout.orders_only_during_opening_hours')}
    </Alert>
  )
}

export default CanOnlyOrderOnOpeningHour
