import { Id, PaymentType } from '@eo-storefronts/eo-core'
import useLogPurchase from '~/src/hooks/analytics/ecommerce/useLogPurchase'
import useOpenExternalUrl from '~/src/hooks/router/useOpenExternalUrl'
import { useEoValue } from '~/src/hooks/useEoState'
import { defaultPaymentRedirectUrls, payWith } from '~/src/services/PaymentService'
import { IS_LOGGED_IN_SELECTOR } from '~/src/stores/authentication'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { CUSTOMER_STATE, GET_CUSTOMER_ID_SELECTOR, GET_CUSTOMER_UUID_SELECTOR } from '~/src/stores/customer'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { BRAND_STATE } from '~/src/stores/brand'
import useSetCustomerState from '~/src/hooks/customer/useSetCustomerState'
import { useFetchAllOrders } from '~/src/hooks/orders/useFetchAllOrders'

interface ReturnsType {
  pay(orderId: Id): void,
}

const usePaymentMethodPay = (): ReturnsType => {
  const { log: logPurchase } = useLogPurchase()
  const { open: openExternalUrl } = useOpenExternalUrl()
  const isLoggedIn = useEoValue(IS_LOGGED_IN_SELECTOR)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const brand = useEoValue(BRAND_STATE)
  const firm = useEoValue(FIRM_SELECTOR)
  const { setCustomer } = useSetCustomerState()
  const { fetch: fetchAllOrders } = useFetchAllOrders()
  const customer = useEoValue(CUSTOMER_STATE)
  const customerId = useEoValue(GET_CUSTOMER_ID_SELECTOR)
  const customerUUID = useEoValue(GET_CUSTOMER_UUID_SELECTOR)

  const pay = (orderId: Id) => {
    if (!checkoutForm.payment_method || !orderId || !firm || !brand) {
      return
    }

    const firmId: Id = firm.id
    const storeId: Id = firm.uuid ?? ''
    const paymentType: PaymentType = checkoutForm.payment_method.payment_method_id as PaymentType

    localStorage.setItem('checkoutForm', JSON.stringify(checkoutForm))

    payWith(
      brand,
      paymentType,
      orderId,
      firmId,
      storeId,
      customerId,
      customerUUID,
      defaultPaymentRedirectUrls(isLoggedIn)
    )
      .then((response: any) => {
        openExternalUrl(response.paymentUrl)
        logPurchase(orderId)
      })
      .catch(() => {
        // Nothing
      })
      .finally(() => {
        if (customer) {
          Promise.all([
            fetchAllOrders(customer.id),
            setCustomer(undefined, true)
          ]).catch(() => {
            // Nothing
          })
        }
      })
  }

  return { pay }
}

export default usePaymentMethodPay
