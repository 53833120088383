import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useEoValue } from '~/src/hooks/useEoState'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { AUTHENTICATION_STATE } from '~/src/stores/authentication'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { PaymentMethodMethodEnum } from '@eo-storefronts/eo-core'

interface ReturnsType {
  redirect(): void,
}

const useRedirectAfterCheckout = (): ReturnsType => {
  const checkoutFormState = useEoValue(CHECKOUT_FORM_STATE)
  const authState = useEoValue(AUTHENTICATION_STATE)
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()

  const redirect = () => {
    if (!authState.loggedInAs) {
      return
    }

    let redirectUrl = `${RoutesEnum.ORDERS}/${RoutesEnum.UPCOMING}`

    if (
      Number(checkoutFormState.payment_method?.payment_method_id) === 1
      || checkoutFormState.payment_method?.method === PaymentMethodMethodEnum.CUSTOM
    ) {
      redirectUrl += '?thank-you'
    }

    return push(resolve(redirectUrl))
  }

  return { redirect }
}

export default useRedirectAfterCheckout
