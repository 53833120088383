import { Box, CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import CheckoutContainer from '~/src/components/checkout/CheckoutContainer'
import useGetCheckoutTemplate from '~/src/pages/checkout/styles/useGetCheckoutTemplate'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import { buildUrl } from '~/src/helpers/router.helper'
import useLogBeginCheckout from '~/src/hooks/analytics/ecommerce/useLogBeginCheckout'
import useCustomerExtraInfo from '~/src/hooks/customer/useCustomerExtraInfo'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import Params from '~/src/router/enums/params.enum'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { AUTHENTICATION_STATE } from '~/src/stores/authentication'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'

const Checkout = () => {
  const pageName: keyof typeof RoutesEnum = 'CHECKOUT'
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const setCheckoutForm = useSetEoState<CheckoutFormState>(CHECKOUT_FORM_STATE)
  const auth = useEoValue(AUTHENTICATION_STATE)
  const customer = useEoValue(CUSTOMER_STATE)
  const { needsExtraInfo } = useCustomerExtraInfo()
  const [ loading, setLoading ] = useState<boolean>(true)

  useGetCheckoutTemplate()
  useLogBeginCheckout()

  useEffect(() => {
    if (auth.loggedInAs === 'customer' && !customer) {
      return
    }

    if (!needsExtraInfo()) {
      setLoading(false)
      return
    }

    push(resolve(buildUrl(RoutesEnum.EXTRA_INFO_CUSTOMER, new Map<Params, keyof typeof RoutesEnum>([
      [ Params.REDIRECT_TO, 'CHECKOUT' ]
    ]))))
  }, [ customer ])

  useEffect(() => {
    let phone = ''

    if (auth.loggedInAs && auth.loggedInAs === 'customer' && customer?.phone) {
      phone = customer.phone
    } else if (auth.guest?.phone) {
      phone = auth.guest.phone
    }

    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      phone
    }))
  }, [ auth.loggedInAs, auth.guest, auth.customer, setCheckoutForm ])

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: .5
      }}
    >
      <DocumentTitleInjector pageName={pageName}/>
      <Box sx={{
        px: 2,
        pb: 2,
        pt: 'var(--eo-safe-area-top)'
      }}>
        <CheckoutContainer/>
        {loading && (
          <Box sx={{
            zIndex: 1000,
            position: 'absolute',
            top: 0,
            left: 0,
            backdropFilter: 'blur(10px)',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <CircularProgress/>
          </Box>
        )}
      </Box>
    </SlideFromRight>
  )
}

export default Checkout
