import { PaymentMethod as PaymentMethodType } from '@eo-storefronts/eo-core'
import { FormControl, RadioGroup } from '@mui/material'
import { ChangeEvent, useEffect } from 'react'
import NoPaymentMethod from '~/src/components/checkout/payments/NoPaymentMethod'
import usePaymentMethods from '~/src/hooks/checkout/usePaymentMethods'
import { useEoState } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import PaymentMethod from './PaymentMethod'
import Base64 from '~/src/utils/Base64Utils'

const PaymentMethods = () => {
  const [ checkoutForm, setCheckoutForm ] = useEoState<CheckoutFormState>(CHECKOUT_FORM_STATE)
  const { paymentMethods } = usePaymentMethods()

  const handlePaymentMethodSelectedChange = (event: ChangeEvent, value: string) => {
    const paymentMethod = Base64.decode<PaymentMethodType>(value)

    if (!paymentMethod) {
      return
    }

    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      payment_method: paymentMethod
    }))
  }

  useEffect(() => {
    if (checkoutForm.payment_method?.payment_method_id) {
      const paymentMethod = paymentMethods.find((pm: PaymentMethodType) => Base64.encode(pm) === Base64.encode(checkoutForm.payment_method))

      if (!paymentMethod) {
        setCheckoutForm((state: CheckoutFormState) => ({
          ...state,
          payment_method: null
        }))
      }
    }

    if (paymentMethods.length === 1) {
      setCheckoutForm((state: CheckoutFormState) => ({
        ...state,
        payment_method: paymentMethods[0]
      }))
    }
  }, [ paymentMethods ])

  if (!paymentMethods.length) {
    return <NoPaymentMethod />
  }

  return (
    <FormControl>
      <RadioGroup
        value={Base64.encode(checkoutForm.payment_method)}
        onChange={handlePaymentMethodSelectedChange}
        sx={{
          flexDirection: 'row',
          gap: 1,
          p: 1
        }}
      >
        {paymentMethods.map((method: PaymentMethodType) => (
          <PaymentMethod
            key={Base64.encode(method)}
            selected={Base64.encode(checkoutForm.payment_method) === Base64.encode(method)}
            payment_method={method}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default PaymentMethods
