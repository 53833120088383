import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import { Button } from '@mui/material'
import { useContext } from 'react'
import InlineAddress from '~/src/components/customers/addresses/InlineAddress'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'

const CheckoutInvoiceAddressSelectButton = () => {
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const { t } = useTranslations()

  if (checkoutForm.invoiceDetails.address) {
    return (
      <Button
        variant='outlined'
        sx={{
          textTransform: 'none',
          p: 3,
          backgroundColor: 'container.main',
          color: 'container.contrastText',
          borderRadius: 2
        }}
        endIcon={<EditIcon color='primary' />}
        onClick={responsiveDialogContext.toggleOpen}
      >
        <InlineAddress address={checkoutForm.invoiceDetails.address} />
      </Button>
    )
  }

  return (
    <Button
      sx={{
        textTransform: 'none',
        color: 'background.contrastText'
      }}
      startIcon={<AddIcon color='primary' />}
      onClick={responsiveDialogContext.toggleOpen}
    >
      {t('checkout.invoice.choose_address')}
    </Button>
  )
}

export default CheckoutInvoiceAddressSelectButton
